import { Box, Typography, Stack, Button } from '@mui/material';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import Iconify from '#/components/shared/ui/Iconify';
import { IBeneficiary } from '#/types/beneficiaries';
import {
  invalidateBeneficiariesQueries,
  useDeleteBeneficiary,
} from '#/api/beneficiaries';
import DeleteModal from '#/components/pages/LastWill/steps/content/Inheritors/childrens/DeleteModal';
import useLocales from '#/hooks/useLocales';

type Props = {
  beneficiary: IBeneficiary;
  selectBeneficiary: (beneficiaryID: IBeneficiary['id']) => void;
};
const BeneficiaryInfo = ({ beneficiary, selectBeneficiary }: Props) => {
  const [openModal, setOpenModal] = useState(false);

  const { name, beneficiary_type, id } = beneficiary;

  const { mutateAsync: deleteBeneficiary } = useDeleteBeneficiary(id);

  const { translate } = useLocales();

  const handleOpenDeleteDialog = () => setOpenModal(true);

  const handleCloseDeleteDialog = () => setOpenModal(false);

  const handleDeleteBeneficiary = async () => {
    await deleteBeneficiary(id)
      .then(() => {
        invalidateBeneficiariesQueries.getBeneficiaries();
        handleCloseDeleteDialog();
        toast.success(
          String(translate('toast_notifications.success.beneficiary_delete'))
        );
      })
      .catch(() => {
        toast.error(
          String(translate('toast_notifications.error.beneficiary_delete'))
        );
      });
  };

  return (
    <Box sx={{ width: 1, p: 1 }}>
      <Stack
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        alignItems="center"
        spacing={{
          xs: 1,
          sm: 2,
        }}
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography color="text.secondary">
            {String(
              translate(
                `global.formLabels.beneficiarieCategories.${beneficiary_type}`
              )
            )}{' '}
            {' : '}
          </Typography>
          <Typography>{name}</Typography>
        </Stack>

        <Stack
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          alignItems="center"
          spacing={1}
        >
          <Box>
            <Button
              variant="text"
              color="error"
              size="small"
              onClick={handleOpenDeleteDialog}
              startIcon={
                <Iconify
                  icon="material-symbols:delete-outline"
                  width={16}
                  height={16}
                />
              }
            >
              <Typography variant="caption">
                {String(translate('global.delete'))}
              </Typography>
            </Button>
            <Button
              onClick={() => selectBeneficiary(id)}
              variant="text"
              size="small"
              startIcon={
                <Iconify icon="material-symbols:edit" width={16} height={16} />
              }
            >
              <Typography variant="caption">
                {String(translate('global.edit'))}
              </Typography>
            </Button>
          </Box>

          {/*<Typography variant="body2">*/}
          {/*  {String(translate('global.formLabels.name'))} :{' '}*/}
          {/*</Typography>*/}
        </Stack>
      </Stack>
      <DeleteModal
        openDeleteDialog={openModal}
        handleCloseDeleteDialog={handleCloseDeleteDialog}
        handleDelete={handleDeleteBeneficiary}
        item={String(translate('global.beneficiarie'))}
      />
    </Box>
  );
};

export default BeneficiaryInfo;
