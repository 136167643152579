import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import BeneficiaryInfo from './BeneficiaryInfo';
import BeneficiaryForm from '#/components/pages/LastWill/steps/content/Inheritors/childrens/Beneficiary/BeneficiaryForm';
import Iconify from '#/components/shared/ui/Iconify';
import {
  invalidateBeneficiariesQueries,
  useAddBeneficiary,
  useGetBeneficiaries,
  useGetBeneficiary,
  useUpdateBeneficiary,
} from '#/api/beneficiaries';
import { IBeneficiary } from '#/types/beneficiaries';
import { FormProvider } from '#/components/shared/hook-form';
import useLocales from '#/hooks/useLocales';
import { invalidateRegisterOfAssets } from '#/api/assetsRegister';

type Props = {
  externalRequest?: boolean;
  setNewBeneficiary?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  handleLastAdded?: VoidFunction;
};

const Beneficiaries = ({
  externalRequest,
  setNewBeneficiary,
  handleLastAdded,
}: Props) => {
  const [updateID, setUpdateID] = useState<string | number | undefined>();
  const [isEdit, setIsEdit] = useState(false);
  const [isAddNew, setIsAddNew] = useState(false);

  const { mutateAsync: addBeneficiary } = useAddBeneficiary();
  const { mutateAsync: updateBeneficiary } = useUpdateBeneficiary(updateID);
  const { data: beneficiaries } = useGetBeneficiaries();
  const { data: beneficiary } = useGetBeneficiary(updateID, !!updateID);

  const { translate } = useLocales();
  const handleUpdate = (id: string | number) => {
    setIsEdit(true);
    setUpdateID(id);
  };

  const handleCloseForm = () => {
    setIsEdit(false);
    setUpdateID(undefined);
    setIsAddNew(false);
  };

  const defaultValues = useMemo(
    () => ({
      name: beneficiary?.name || '',
      beneficiary_type: beneficiary?.beneficiary_type || '',
      date_of_birth: beneficiary?.date_of_birth || '',
      street: beneficiary?.street || '',
      house_number: beneficiary?.house_number || '',
      country: beneficiary?.country || 'CH',
      city: beneficiary?.city || '',
      zip_code: beneficiary?.zip_code || '',
    }),
    [beneficiary]
  );

  const methods = useForm<IBeneficiary>({
    defaultValues,
  });

  const {
    handleSubmit,
    watch,
    reset,
    formState: { isDirty },
  } = methods;

  const onSubmit = async (data: IBeneficiary) => {
    const reqData = {
      ...data,
      ...Object.fromEntries(
        Object.entries(data).map(([key, value]) => [
          key,
          value === '' ? null : value,
        ])
      ),
      date_of_birth: data.date_of_birth
        ? new Date(data.date_of_birth).toISOString().split('T')[0]
        : null,
    };
    if (isEdit) {
      await updateBeneficiary(reqData)
        .then(() => {
          toast.success(
            String(translate('toast_notifications.success.beneficiary_update'))
          );
          setIsEdit(false);
          invalidateBeneficiariesQueries.getBeneficiary(updateID);
          setUpdateID(undefined);
          reset(defaultValues);
          invalidateBeneficiariesQueries.getBeneficiaries();
          invalidateRegisterOfAssets.commonBeneficiariesList();
        })
        .catch(() => {
          toast.error(
            String(translate('toast_notifications.error.beneficiary_update'))
          );
        });
    } else {
      await addBeneficiary(reqData)
        .then(() => {
          toast.success(
            String(translate('toast_notifications.success.beneficiary_create'))
          );
          setIsAddNew(false);
          reset(defaultValues);
          invalidateBeneficiariesQueries.getBeneficiaries();
          invalidateRegisterOfAssets.commonBeneficiariesList();
          setNewBeneficiary?.(false);
          handleLastAdded?.();
        })
        .catch(() => {
          toast.error(
            String(translate('toast_notifications.error.beneficiary_create'))
          );
        });
    }
  };

  const hasBeneficiaries = beneficiaries?.length !== 0;
  const isSubmitDisabled =
    (isEdit && JSON.stringify(defaultValues) === JSON.stringify(watch())) ||
    (!isEdit && !isDirty);

  useEffect(() => {
    if (isEdit && beneficiary) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line
  }, [isEdit, beneficiary]);

  return (
    <Box sx={{ width: 1 }}>
      {hasBeneficiaries &&
      !isAddNew &&
      !updateID &&
      !isEdit &&
      !externalRequest ? (
        <Box>
          {beneficiaries?.map((beneficiary) => (
            <BeneficiaryInfo
              key={`beneficiary-${beneficiary.id}`}
              beneficiary={beneficiary}
              selectBeneficiary={handleUpdate}
            />
          ))}
          <Divider />
          <Button
            variant="contained"
            size="small"
            color="secondary"
            sx={{
              my: 2,
            }}
            startIcon={<Iconify icon="mdi:plus-circle" />}
            onClick={() => setIsAddNew(true)}
          >
            <Typography variant="caption">
              {String(translate('lastWill.stepOne.addAnotherBenefiter'))}
            </Typography>
          </Button>
        </Box>
      ) : (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <BeneficiaryForm
            handleSubmit={handleSubmit(onSubmit)}
            watch={watch}
            isSubmitDisabled={isSubmitDisabled}
            closeForm={handleCloseForm}
            formState={methods.formState}
            setNewBeneficiary={setNewBeneficiary}
          />
        </FormProvider>
      )}
    </Box>
  );
};

export default Beneficiaries;
